import {Viewer,UrlTemplateImageryProvider,Credit,KmlDataSource,HeadingPitchRange} from '../../node_modules/cesium';
import 'cesium/Source/Widgets/widgets.css';
import React from 'react';

class CesiumContainer2 extends React.Component {
  componentDidMount() {
    const viewer = new Viewer('cesiumContainer2', {
      imageryProvider: 
      new UrlTemplateImageryProvider({
        url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
        // url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
  //      url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/',fileExtension: 'jpg',
        credit: new Credit('地理院タイル', '', 'https://maps.gsi.go.jp/development/ichiran.html')
      }),
      baseLayerPicker: false,
      timeline : false,
      animation : false,
      fullscreenButton: false,
      homeButton: false,
      vrButton: false,
      geocoder:false,
      sceneModePicker:false,
      navigationHelpButton:false,
    });

    var options = {
      camera: viewer.scene.camera,
      canvas: viewer.scene.canvas,
    };
    console.log(this.props.dir+this.props.file+this.props.ext);
    var dataSourcePromise = viewer.dataSources.add(KmlDataSource.load(this.props.dir+this.props.file+this.props.ext,options));
    viewer.zoomTo(dataSourcePromise, new HeadingPitchRange(0, -1, 50000));
    viewer._cesiumWidget._creditContainer.style.display = "none";
  }
 
  render() {
    return <div><div id="cesiumContainer2" /></div>;
  }
}

export default CesiumContainer2

