import React, { useState } from "react"
import { Link } from "gatsby"

import CesiumContainer from "./cesiumContainer"
import CesiumContainer2 from "./cesiumContainer2"
import CesiumContainer3 from "./cesiumcontainer3"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import SampleCsv from "../../static/kml/sample.csv"



// const kml1 = 'https://empire-dev3.kashika.net/kml/'+'105'+'/'+'福岡県久留米市' + '_' + 'H26小売業販売額×公共交通利用圏.kmz';

// const User = props => (
//   <div>
//     <Link to={props.url}>
//       <div>
//           <img src={props.avatar}alt="" />
//         <div>
//           <h2><span>{props.themename}</span>{props.username}</h2>
//           <p>{props.excerpt}</p>
//         </div>
//       </div>
//     </Link>
//   </div>
//   )
  

// function Example() {
//   // Declare a new state variable, which we'll call "count"
//   const [count, setCount] = useState(0);

//   return (
//     <div>
//       <p>You clicked {count} times</p>
//       <button onClick={() => setCount(count + 1)}>
//         Click me
//       </button>
//     </div>
//   );
// }


// const kmlsrc1 = 'https://empire-dev3.kashika.net/Cesium/Apps/kashika/?sourceid='+"101"+'/'+ "福岡県糸島市" + '_' + "夜間人口×夜間人口.kmz";
const kmlsrc1 = 'https://empire-dev3.kashika.net/Cesium/Apps/kashika3/?sourceid=101/福岡県糸島市_夜間人口×夜間人口.kmz';
const kmldown1 = 'https://empire-dev3.kashika.net/kml/101/"福岡県糸島市_夜間人口×夜間人口.kmz';



const IndexPage = () => {
  
  const [kml, setKml] = useState(0);





  return (
  <Layout>
    <SEO title="Home" />
    <h1>新プレビュー画面テスト</h1>
    <p>新プレビュー画面をご確認ください</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
    </div>

<div style={{display:"flex"}}>

    <div id="cesium1">

    <input type="button" value="糸島時系列" onclick="buttonClick()"></input>

    {/* <Example/> */}


     <div>
       <p>You clicked {kml} times</p>
       <button onClick={() => setKml("itoshimatimespan.kml")}>
         Click me
       </button>
     </div>



    <CesiumContainer
      dir="/kml"
      file="/itoshimatimespan.km"
      ext="z"
      // file="/itoshimatimespan.kml"
      year="2100"
    />

    <Link
     to="/cesiumWindow"
     state={{
       dir:"/kml",
       file:"/sample.kml",
      }}
    >
      全画面表示（ナビゲーション付き）</Link>

    


      </div>
      <div id="cesium2">
        <div>
          
        </div>
    <CesiumContainer2
      dir="/kml"
      file="/sample."
      ext="kml"
    />
    <Link
     to="/cesiumWindow"
     state={{
      dir:"/kml",
      file:"/sample.kml"
     }}
    >
      全画面表示</Link>
      </div>
    </div>
<div><CesiumContainer3/></div>


    <br/>
    <br/>
    <br/>

    <div>
      <Link
        to="https://empire-dev14.kashika.net/"
      >
        <h2>ランディングページ、データのON/OFFなどの機能テストはこちらから</h2>
      </Link>
    </div>


<div>

<iframe
            name="iframe1"
            src={kmlsrc1}
            frameBorder="0"
            allowFullScreen
            title="cesium"
            width="600px"
            height="300px"
          />

</div>


  </Layout>
  );
    }


export default IndexPage
